/* eslint-disable no-magic-numbers,complexity,max-lines-per-function */
import {
  PROTOCOL_HTTPS,
  defaultPlatform,
  domainMappingPlatform,
  portalDomainMappingPlatform,
} from '@/helpers/platform/data';
import { createGetPortalUrl } from '@/helpers/platform/createGetPortalUrl';
import { createGetUrl } from '@/helpers/platform/createGetUrl';
import { inBlacklist } from '@/helpers/platform/inBlacklist';
import { inStagingBlacklist } from '@/helpers/platform/inStagingBlacklist';
import newRelic from '@/services/new-relic';

const LOCALHOST_PATH = 'localhost';

export let domain;
export let getPortalUrl;
export let getUrl;
export let platform;
export let portalDomain;
export let subdomain;
export let environment;

/**
 * Returns all the variables based on the host.
 *
 * @example // for a full host name
 * // https://aardbijenbij.petertesting.assets.staging.sendmyparcel.me
 * // https://<portalName>.<subStaging>.<type>.<subdomain>.<environment>
 *
 * @param {string} host
 * @returns {{
 *   domain: String,
 *   getPortalUrl: Function<String>,
 *   getUrl: Function<String>,
 *   platform: String,
 *   portalDomain: String,
 *   subdomain: String,
 *   environment: String,
 * } | Error}
 */
export const getVariables = (host = window.location.host) => {
  let environment;
  let getUrl;
  let platform;
  let protocol;
  let subEnvironment;
  let subdomain = null;

  const parts = host.split('.');
  const [first, second, third, fourth] = parts;

  switch (parts.length) {
    // Can only be "localhost"
    case 1:
      // localhost -> api.dev.myparcel.nl
      environment = 'dev';
      platform = defaultPlatform;
      getUrl = createGetUrl({ environment, platform });
      break;

    case 2:
      if (second === LOCALHOST_PATH) {
        platform = defaultPlatform;
        if (first === 'prod') {
          // prod.localhost -> https://api.myparcel.nl
          environment = 'prod';
          getUrl = createGetUrl({ environment, platform });
        } else {
          // <whatever>.localhost -> //api.<whatever>.myparcel.nl
          environment = first;
          getUrl = createGetUrl({ environment, platform });
        }
      } else {
        // myparcel.me -> https://api.myparcel.nl
        platform = first;
        protocol = PROTOCOL_HTTPS;
        getUrl = createGetUrl({ protocol, platform });
      }
      break;

    case 3:
      if (host.endsWith(LOCALHOST_PATH)) {
        if (second === 'prod') {
          // demo.prod.localhost -> https://api.myparcel.nl
          subdomain = first;
          environment = second;
          platform = defaultPlatform;
          protocol = PROTOCOL_HTTPS;
          getUrl = createGetUrl({ subdomain, protocol, platform });
        } else {
          // demo.test.localhost -> //api.test.flespakket.nl
          environment = second;
          platform = defaultPlatform;
          getUrl = createGetUrl({ environment, platform });
        }
      } else {
        // demo.flespakket.me -> https://api.flespakket.nl
        if (inBlacklist(first)) {
          environment = first;
        } else {
          subdomain = first;
        }

        platform = second;
        protocol = PROTOCOL_HTTPS;
        getUrl = createGetUrl({ environment, subdomain, protocol, platform });
      }
      break;

    case 4:
      if (host.endsWith(LOCALHOST_PATH)) {
        // demo.portal.staging.localhost -> //portal.api.staging.myparcel.nl
        subdomain = first;
        subEnvironment = second;
        environment = third;
        platform = defaultPlatform;
        getUrl = createGetUrl({ subdomain, subEnvironment, environment, platform });
      } else {
        // demo.staging.flespakket.me -> //api.staging.flespakket.nl
        const isSubEnvironment = second === 'staging' && inStagingBlacklist(first);
        subdomain = isSubEnvironment ? null : first;

        if (isSubEnvironment) {
          subEnvironment = first;
        }

        environment = second;
        platform = third;
        getUrl = createGetUrl({ subdomain, environment, subEnvironment, platform });
      }
      break;

    case 5:
      // demo.jeffrey.staging.myparcel.me -> //jeffrey.api.staging.myparcel.nl
      subdomain = first;
      subEnvironment = second;
      environment = third;
      platform = fourth;
      getUrl = createGetUrl({ subdomain, subEnvironment, environment, platform });
      break;

    default:
      const error = new Error('URL is too long, we cannot interpret this format');

      newRelic.noticeError(error);

      throw new Error(error);
  }

  const portalDomain = portalDomainMappingPlatform[platform];
  const getPortalUrl = createGetPortalUrl({
    domain: portalDomain, platform, subdomain, environment, subEnvironment,
  });

  subdomain = inBlacklist(subdomain) ? null : subdomain;
  environment = inBlacklist(subdomain) ? subdomain : environment;

  return {
    domain: domainMappingPlatform[defaultPlatform],
    portalDomain,
    environment,
    getPortalUrl,
    getUrl,
    platform,
    subdomain,
  };
};

setVariables();

/**
 * Update the exported variables.
 *
 * @param {Object} variables
 */
export function setVariables(variables = getVariables()) {
  ({ platform, getPortalUrl, portalDomain, environment, getUrl, domain, subdomain } = variables);
}
