export const FLESPAKKET = 'flespakket';
export const MYPARCEL = 'myparcel';
export const SENDMYPARCEL = 'sendmyparcel';

export const DOMAIN_FLESPAKKET = 'flespakket.nl';
export const DOMAIN_MYPARCEL = 'myparcel.nl';
export const DOMAIN_SENDMYPARCEL = 'sendmyparcel.be';

export const DOMAIN_PORTAL_FLESPAKKET = 'flespakket.me';
export const DOMAIN_PORTAL_MYPARCEL = 'myparcel.me';
export const DOMAIN_PORTAL_SENDMYPARCEL = 'sendmyparcel.me';

// Maps platforms to their domains
export const domainMappingPlatform = {
  [FLESPAKKET]: DOMAIN_FLESPAKKET,
  [MYPARCEL]: DOMAIN_MYPARCEL,
  [SENDMYPARCEL]: DOMAIN_SENDMYPARCEL,
};

export const portalDomainMappingPlatform = {
  [FLESPAKKET]: DOMAIN_PORTAL_FLESPAKKET,
  [MYPARCEL]: DOMAIN_PORTAL_MYPARCEL,
  [SENDMYPARCEL]: DOMAIN_PORTAL_SENDMYPARCEL,
};

export const PROTOCOL_HTTPS = 'https://';
export const PROTOCOL_NONE = '//';

export const defaultPlatform = MYPARCEL;
