import { environment } from '@/helpers/platform';

// Types of environments such as dev, staging, etc.
export const isStaging = environment === 'staging';

// Types of builds
export const env = process.env.NODE_ENV;

export const isDev = env === 'development';
export const isProd = env === 'production' || (!isStaging && !isDev);
