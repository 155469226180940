import { createGetUrl } from '@/helpers/platform/createGetUrl';
import { portalDomainMappingPlatform } from '@/helpers/platform/data';

/**
 * @param {Object} object - Object with url parts.
 *
 * @returns {Function<String>}
 */
export const createGetPortalUrl = (object) => {
  const {
    environment,
    platform,
    subEnvironment,
    subdomain,
  } = object;

  return createGetUrl(
    {
      domain: portalDomainMappingPlatform[platform],
      environment,
      platform,
      subEnvironment,
      subdomain,
    },
    false,
  );
};
