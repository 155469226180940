/**
 * Named staging environments to exclude ONLY if the environment is staging.
 *
 * @type {String[]}
 */
const stagingBlacklist = [
  'alex',
  'alwin',
  'edie',
  'jeffrey',
  'jona',
  'jos',
  'linku',
  'misjnoe',
  'peter',
  'tom',
];

/**
 * Check if the given subdomain is blacklisted on staging.
 *
 * @param {String} subdomain - Subdomain.
 *
 * @returns {Boolean}
 */
export function inStagingBlacklist(subdomain) {
  return stagingBlacklist.includes(subdomain);
}
