/**
 * Environments that should never be seen as subdomains.
 *
 * @type {string[]}
 */
const blacklist = [
  'acceptance',
  'dev',
  'prod',
  'production',
  'release',
  'staging',
  'testing',
];

/**
 * Check if the given subdomain is blacklisted.
 *
 * @param {string} subdomain - Subdomain.
 *
 * @returns {boolean}
 */
export function inBlacklist(subdomain) {
  return blacklist.includes(subdomain);
}
