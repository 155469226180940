import { DOMAIN_MYPARCEL, domainMappingPlatform } from '@/helpers/platform/data';
import { getProtocol } from '@/helpers/platform/getProtocol';
import { inBlacklist } from '@/helpers/platform/inBlacklist';

/**
 * Create a getUrl function from a given config object.
 *
 * @param {Object} object - Object with url parts.
 * @param {boolean} protocol - Include protocol or not.
 *
 * @returns {Function<string>}
 */
export const createGetUrl = (object, protocol = true) => {
  const {
    subdomain,
    subEnvironment,
    platform,
    domain,
  } = object;

  let { environment } = object;

  environment = inBlacklist(subdomain) ? subdomain : environment;

  // Remove the environment if it turns out to be production so it doesn't get added into the URL.
  if (environment === 'prod') {
    environment = null;
  }

  const suffix = (item) => {
    return item ? `${item}.` : '';
  };

  return (type = null) => {
    const newDomain = type === 'api' ? DOMAIN_MYPARCEL : domainMappingPlatform[platform];
    return [
      protocol ? getProtocol(environment) : null,
      suffix(subEnvironment),
      suffix(type),
      suffix(environment),
      // Override domain name if api url is being requested.
      domain || newDomain,
    ]
      .filter((val) => !!val)
      .join('');
  };
};
