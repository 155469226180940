import '@/assets/scss/style.scss';
import envVariables from '@/services/env-variables';
import { isProd } from '@/helpers/environment';
import newRelic from '@/services/new-relic';

/**
 * Fetch and set the environment variables.
 *
 * @returns {Promise<void>}
 */
function init() {
  return new Promise((resolve) => {
    resolve(envVariables.fetch());
  }).then(() => {
    import('./index.js');
  }).catch((e) => {
    newRelic.noticeError(e);

    import('./index.js');
  });
}

/**
 * Temporary fallback to use the environment variables only for development.
 *
 * @todo Add the environment variables to the build process.
 */
if (isProd) {
  import('./index.js');
} else {
  init();
}
