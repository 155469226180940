import { PROTOCOL_HTTPS, PROTOCOL_NONE } from '@/helpers/platform/data';

/**
 * @param {string} environment - Environment to check.
 *
 * @returns {string}
 */
export function getProtocol(environment) {
  switch (environment) {
    case 'staging':
      return PROTOCOL_NONE;
    default:
      return PROTOCOL_HTTPS;
  }
}
